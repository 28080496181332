<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Add Banner'">
          <template v-slot:preview>
            <v-card
              class="rounded-lg gray darken-1"
              @dragover.prevent="dropFiles = 1"
              @dragleave.prevent="dropFiles = 0"
              @drop.prevent="dragOrDrop($event)"
              v-if="images.length === 0"
            >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="mt-5 d-flex justify-center">
                        <v-file-input
                          v-model="images"
                          class="justify-center"
                          prepend-icon="mdi-upload"
                          accept=".jpg, .png, .jpeg"
                          @change="previewImages"
                          multiple
                          hide-input
                        >
                        </v-file-input>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="d-flex justify-center">
                        <p class="text-h5 mt-5">
                          Click to Upload or Drop Your Image here
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
            <div class="rounded-lg gray darken-1">
              <v-card-text>
                <v-container v-if="images.length > 0">
                  <v-btn
                    class="m-4"
                    color="red"
                    dark
                    :ripple="false"
                    @click="reset"
                  >
                    Clear All
                  </v-btn>
                  <v-row>
                    <v-col
                      v-for="(banner, index) of banners"
                      :key="index"
                      cols="12"
                      md="4"
                    >
                      <v-img
                        width="800"
                        height="400"
                        :src="banner.previewImage"
                      ></v-img>
                      <v-btn
                        class="deleteBtn"
                        @click="showConfirm(index)"
                        absolute
                        rounded
                        right
                        top
                      >
                        <v-icon small color="red">mdi-delete</v-icon>
                      </v-btn>
                      <v-row class="mt-2">
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="banner.width"
                            type="number"
                            label="Width"
                            :error="$v.banners.$each[index].width.$error"
                            hide-details
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="banner.height"
                            type="number"
                            label="Height"
                            :error="$v.banners.$each[index].height.$error"
                            hide-details
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="banner.link"
                            label="Link"
                            :error="$v.banners.$each[index].link.$error"
                            hide-details
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-select
                            v-model="banner.type"
                            label="Banner Type"
                            :items="types"
                            item-text="name"
                            item-value="value"
                            :error="$v.banners.$each[index].type.$error"
                            hide-details
                            dense
                            outlined
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-switch
                            v-model="banner.is_banner"
                            label="Is Banner"
                            dense
                            outlined
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  dark
                  :ripple="false"
                  @click="saveImages"
                  >Upload
                </v-btn>
                <v-btn color="red darken-1" dark @click="goToBack">
                  Close
                </v-btn>
              </v-card-actions>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </v-app>
</template>

<script>
import BannerService from "../../../service/banner/BannerService";
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

const bannerService = new BannerService();
export default {
  name: "CreateOrUpdate",
  mixins: [validationMixin],
  components: {
    KTCodePreview
  },
  data() {
    return {
      banners: [],
      images: [],
      items: [],
      files: [],
      fileType: [],
      max: 10,
      dropFiles: 0,
      types: [
        { name: "Main Banner", value: "main_banner" },
        { name: "Sub Banner", value: "sub_banner" },
        { name: "Footer Banner", value: "footer_banner" }
      ]
    };
  },
  validations: {
    banners: {
      $each: {
        width: {
          required
        },
        height: {
          required
        },
        link: {
          required
        },
        type: {
          required
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Banner" },
      { title: "Manage Banner" }
    ]);
  },

  methods: {
    goToBack() {
      this.$router.push({ name: "banner" });
    },

    closeModal() {
      this.$emit("close-modal");
      this.reset();
    },

    showConfirm(index) {
      this.$confirm({
        message: `Are you Sure ??`,
        button: {
          yes: "Yes",
          no: "No"
        },
        callback: confirm => {
          if (confirm) {
            this.banners.splice(index, 1);
            this.images.splice(index, 1);
          }
        }
      });
    },

    saveImages() {
      this.$v.$touch();
      if (this.banners.length > 0) {
        if (!this.$v.banners.$invalid) {
          let form = this.setFormData();
          bannerService.store(form).then(response => {
            if (response.data.status === "OK") {
              this.$snotify.success("Upload Successfully !!!");
              this.$router.push({
                name: "banner"
              });
            }
          });
        } else {
          setTimeout(() => {
            this.$v.$reset();
          }, 3000);
        }
      } else {
        this.$snotify.error("Please Upload a Image");
      }
    },

    previewImages() {
      let totalUploadImage = 0;

      if (this.images) {
        totalUploadImage = this.images.length;
      }
      if (this.checkMaxFile(totalUploadImage)) {
        return;
      }
      if (this.dropFiles !== 0) return;
      if (!this.images.length) return;
      for (let i = 0; i < this.images.length; i++) {
        this.banners.push({
          width: "",
          height: "",
          image: this.images[i],
          previewImage: URL.createObjectURL(this.images[i]),
          type: "",
          link: "",
          is_banner: true
        });
      }
    },

    dragOrDrop(event) {
      let totalDropImage = 0;
      totalDropImage = event.dataTransfer.files.length + this.images.length;
      if (event.dataTransfer.files) {
        if (
          !this.checkFileType(event.dataTransfer.files) &&
          !this.checkMaxFile(totalDropImage)
        ) {
          this.images.push(...event.dataTransfer.files);
          this.previewImages();
        }
      }
      this.dropFiles = 0;
    },
    setFormData() {
      let form = new FormData();
      for (let i = 0; i < this.banners.length; i++) {
        form.append("file[]", this.banners[i].image);
        form.append("width[]", this.banners[i].width);
        form.append("height[]", this.banners[i].height);
        form.append("type[]", this.banners[i].type);
        form.append("link[]", this.banners[i].link);
        form.append("is_banner[]", this.banners[i].is_banner);
      }
      form.append("uploadFor", "banner");
      return form;
    },

    reset() {
      this.images = [];
      this.banners = [];
    },

    checkFileType(files) {
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].type.startsWith("image") === true) {
            this.fileType.push(true);
          }
        }
      }
      if (this.fileType.length !== files.length) {
        this.$snotify.error("Unsupported File Type !!");
        return true;
      }
      this.fileType = [];
      return false;
    },

    checkMaxFile(totalImage) {
      if (this.max && totalImage > this.max) {
        this.$snotify.error("Maximum Image Upload is " + this.max);
        this.images = [];
        return true;
      }
      return false;
    }
  }
};
</script>
<style lang="scss">
.mdi-upload {
  font-size: 160px !important;
}

.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
  right: 16px !important;
}

.col {
  padding: 5px !important;
}

.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.deleteBtn {
  min-width: 36px !important;
  padding: 0 !important;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: #f5f5f5;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
